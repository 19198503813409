@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&family=Playfair+Display:wght@400;700&family=Roboto:wght@300;400;500&display=swap');
:root {
    --primary-color: #2D4059;          
    --secondary-color: #EAECF0;        
    --background-color: #F4F7FC;       
    --accent-color: #A6B1C3;           
    --text-color: #333333;             
    --white-color: #FFFFFF;            
    --button-hover-color: #4A6379;     
    --gradient-color: linear-gradient(135deg, #2D4059, #4A6379, #3B4F6E);  
}

body {
    font-family: 'Roboto', sans-serif;
    background-color: var(--background-color);
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.home-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 20px;
    width: 90%;
    background: var(--gradient-color);
    margin: 30px auto;
    min-height: 80vh;
    border-radius: 15px;
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.2);
    animation: fadeIn 1.5s ease-in-out;
    
}

.header {
    margin-bottom: 20px;
    animation: fadeIn 1.5s ease-in-out;
}

.header h1 {
    font-size: 48px;
    font-family: 'Playfair Display', serif;
    color: var(--primary-color);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.header p {
    font-size: 20px;
    font-family: 'Playfair Display', serif;
    color: var(--secondary-color);
}

.book-container {
    flex: 1 1 auto;
    width: 80%;
    max-width: 900px;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 15px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    margin: auto;
    animation: slideIn 1s ease-in-out;
    padding: 20px;
}

.page {
    display: none;
}

.page.active {
    display: block;
}

.page h3 {
    margin-bottom: 20px;
    font-size: 32px;
    font-family: 'Playfair Display', serif;
    color: var(--primary-color);
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.menu-item {
    flex: 1 1 calc(45% - 10px);
    margin-bottom: 10px;
    padding: 15px;
    background-color: var(--white-color);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    transition: transform 0.3s, box-shadow 0.3s;
    max-width: 250px;
    text-align: left;
    position: relative;
    overflow: hidden;
}

.menu-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: transform 0.3s;
    transform: scale(0);
    border-radius: 10px;
}

.menu-item:hover::before {
    transform: scale(1);
}

.menu-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.menu-item span {
    font-weight: bold;
    color: var(--text-color);
    z-index: 1;
}

.menu-price {
    color: var(--secondary-color);
    font-weight: bold;
    z-index: 1;
    align-self: flex-end;
}

.button-container {
    margin-top: 20px;
    padding: 20px 0;
}

.next-button {
    padding: 12px 24px;
    font-size: 18px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}



.next-button:hover {
    background-color: var(--button-hover-color);
    transform: scale(1.05);
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.image-container {
    width: 100%;
    height: 70vh; 
    background-image: url('../Images/IMG-2531-1024x768.jpg');
    background-size: cover;
    background-position: center;
    z-index: -10; 
    margin-top: 0px; 
    position: relative;
}

.image-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: var(--white-color);
    font-family: 'Dancing Script', cursive;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.image-text h1 {
    font-size: 80px;
    margin-bottom: 10px;
    font-weight: 700;
}

.image-text p {
    font-size: 30px;
    font-weight: 700;
}

.extra-boxes {
    width: 100%;
    position: relative;
    align-items: center;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.extra-boxes1 {
    width: 100%;
    position: relative;
    align-items: center;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}

.text-box{
    width: 100%;
    padding: 20px;
    height: 400px;
    text-align: center;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.text-box p, .image-box p {
    margin-top: 50px;
    color: #333;
    font-size: 30px; 
    line-height: 1.6; 
    font-weight: 700;
    align-items: center;
    text-align: center;
    font-family: 'Playfair Display';
}


.text-box h2, .image-box h2 {
    margin-top: auto;
    color: #601700; /* Changed to the specified color */
    font-size: 40px; /* Adjust as needed */
    font-weight: 600; /* Adjust as needed */
    line-height: 1.4; /* Adjust as needed */
}
.text-box2 h2, .image-box2 h2 {
    margin-top: auto;
    color: #601700; /* Changed to the specified color */
    font-size: 24px; /* Adjust as needed */
    font-weight: 600; /* Adjust as needed */
    line-height: 1.4; /* Adjust as needed */
}
.text-box2 p, .image-box2 p {
    margin-top: 50px;
    color: #333; 
    font-size: 16px; 
    
    line-height: 1.6; 
    font-weight: 700;
    align-items: center;
    text-align: center;
    font-family: 'Playfair Display';
}

.image-box {
    background-image: url('../Images/IMG_20240322_173731.jpg');
    background-size: cover;
    background-position: center;
}

.image-box img {
    width: 100%;
    border-radius: 10px;
}


.text-box2 , .image-box2 {
    width: 50%;
    padding: 20px;
    height: 400px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.text-box2 h2, .image-box2 h2 {
    margin-bottom: 10px;
    color: var(--primary-color);
}

.image-box2 {
    background-image: url('../Images/IMG_2231.JPG');
    background-size: cover;
    background-position: center;
}

.image-box2 img {
    width: 100%;
    border-radius: 10px;
}

@media (max-width: 1068px) {
    .home-container {
        width: 90%;
        padding: 15px;
    }
    
    .book-container {
        width: 90%;
        padding: 15px;
    }

    .menu-item {
        flex: 1 1 calc(45% - 10px);
        max-width: 45%;
        padding: 10px;
    }

    .extra-boxes {
        flex-direction: column;
        align-items: center;
    }

    .text-box, .image-box {
        width: 90%;
    }

    .image-container {
        height: 60vh;
    }

    .image-text h1 {
        font-size: 40px;
    }

    .image-text p {
        font-size: 20px;
    }
}

@media (max-width: 520px) {
    .header h1 {
        font-size: 32px;
    }

    .header p {
        font-size: 16px;
    }

    .page h3 {
        font-size: 24px;
    }

    .next-button {
        padding: 10px 18px;
        font-size: 16px;
    }

    .image-text h1 {
        font-size: 30px;
    }

    .image-text p {
        font-size: 18px;
    }

    .text-box, .image-box {
        width: 90%;
        height: 200px;
    }
    .text-box p{
        
        margin-top: 0;
        font-size: 15px;
    }
    .text-box h2, .image-box h2 {
        font-size: 20px;
    }
    
    .text-box2, .image-box2 {
        width: 90%;
        height: 200px;
    }
    .text-box2 p, .image-box2 p {
        margin-top: 0;
        font-size: 12px;
    }
    .text-box2 h2, .image-box2 h2 {
        font-size: 15px;
    }

    ul {
        gap: 5px;
    }
    
    .menu-item {
        flex: 1 1 calc(40% - 5px);
        max-width: 48%;
        padding: 10px;
    }
    
    .extra-boxes {
        margin-top: 20px;
    }
    
    .image-container {
        height: 50vh;
    }
    
    .book-container {
        padding: 10px;
    }
    
    .home-container {
        padding: 10px;
    }
}
