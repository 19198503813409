@tailwind base;
@tailwind components;
@tailwind utilities;

@import "flowbite";


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    background-color: #3D1308;
}
.App{
  margin: auto;
  text-align: center;
}
.button1{
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  background-color: aquamarine;
  font-weight: bolder;
  font-size: 19px;
}
